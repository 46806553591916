import { getLang } from '../lang';
import { generateAuthorizationHeader } from './generateAuthorizationHeader';
import { gracefulFetch } from './gracefulFetch';
import { postEvent } from './postEvent';

interface Props {
  uuid: string;
  phoneNo: string;
  lang?: string;
  mock?: {
    enabled: boolean;
    expectSuccess: boolean;
  };
}

export const postSMS = async ({
  uuid,
  phoneNo,
  lang = getLang(),
  mock = {
    enabled: process.env.NODE_ENV === 'development',
    expectSuccess: true
  }
}: Props) => {
  let response: Response | null = null;

  if (mock && mock.enabled) {
    await delay(2000);
    return {
      ok: mock.expectSuccess,
      status: mock.expectSuccess ? 200 : 405
    };
  }

  try {
    const headers = await generateAuthorizationHeader();

    response = await gracefulFetch({
      fetch: async () =>
        fetch(process.env.REACT_APP_API + '/cases/' + uuid + '/sms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...headers
          },
          body: JSON.stringify({
            phoneNo,
            lang
          })
        })
    });

    postEvent({
      uuid,
      event: 'WEB.JUMP.SMS'
    });
  } catch (error) {
    console.warn(error);

    postEvent({
      uuid,
      event: 'WEB.JUMP.SMS',
      details: {
        error
      }
    });
  }

  if (response && response.ok) {
    return { status: response.status, ok: response.ok };
  }

  return { status: 404, ok: false };
};
