import { useState, useEffect, useCallback } from 'react';
import { getCaseStatus } from '../api/getCaseStatus';
import {
  useStateValue,
  changeUUIDAndResponseURIAction,
  changeCoBrandingAction
} from './useState';
import { useRouter, useLocation } from '../react-router-hooks';
import { routes } from '../constants/constants';
import { useWideScreen } from './useWide';
import { clearLocalStorage } from '../helper/clearLocalStorage';
import { postEvent } from '../api/postEvent';
import { getCoBranding } from '../api/getCoBranding';
import { getBrowserRedirectURI } from '../api/getBrowserRedirectURI';
import { prepareRedirectURL } from '../helper/prepareRedirectURL';
import { getItem, setItem } from '../helper/storage';
import { delay } from 'q';
import { getESignStatus } from '../api/getESignState';

interface Props {
  initialCaseStatus?: CaseStatus | {};
}

export const useHandleStatus = ({ initialCaseStatus }: Props) => {
  const [caseStatus, setCaseStatus]: [any, any] = useState(undefined);
  const [
    { uuid, responseURI, coBranding, esignData, caseType },
    dispatch
  ] = useStateValue();
  const { navigate, location } = useLocation();
  const isWideScreen = useWideScreen();

  const fetchAndSetStatus = useCallback(async () => {
    //TODO: [mr] track: heartbeat

    if (!uuid) {
      return;
    }

    try {
      const newCaseStatus = await getCaseStatus({ uuid });
      const esignCanProceed = await getESignStatus({
        uuid,
        ...esignData,
        caseType: caseType
      });

      if (!esignCanProceed && newCaseStatus?.state === 'complete') {
        newCaseStatus.state = 'pending';
        newCaseStatus.reason = '';
        newCaseStatus.success = false;
      }

      if (newCaseStatus && newCaseStatus.state) {
        setCaseStatus(newCaseStatus);
      } else {
        await delay(6000);
        fetchAndSetStatus();
        // [dg — 17 Nov 2020] In the short-term, when NEFE-1684 is implemented,
        // this will likely need to add a max number of retries, together with
        // displaying the error somehow
      }
    } catch (e) {
      //TODO: [mr] handle offline
      // NOTE: [DG, 17 Nov 2020] This path is probably never really taken
      // because I think(TM) `getCaseStatus` never returns an error
    }
  }, [uuid, setCaseStatus]);

  useEffect(() => {
    if (!uuid) {
      return;
    }

    fetchAndSetStatus();
  }, [fetchAndSetStatus, uuid]);

  useEffect(() => {
    if (!caseStatus) {
      return;
    }

    if (!coBranding) {
      const publicSPID = getItem('test_public_spid') || caseStatus.publicSPID;

      if (dispatch && publicSPID) {
        setItem('public_spid', publicSPID);
        getCoBranding({
          uuid,
          publicSPID
        }).then(coBranding => {
          dispatch(changeCoBrandingAction({ coBranding }));
        });
      }
    }

    switch (caseStatus.state) {
      case 'created': {
        if (caseStatus.toSubmit.length) {
          if (isWideScreen) {
            navigate(routes.IDENT);
          } else if (!isWideScreen) {
            navigate(routes.HOME);
          }

          const timer = setTimeout(fetchAndSetStatus, 3000);
          return () => clearInterval(timer);
        } else if (!caseStatus.reason) {
          //case not started yet
          if (isWideScreen && location.pathname === routes.IDENT) {
            navigate(routes.HOME);
          } else if (!isWideScreen) {
            navigate(routes.HOME);
          }
        }

        const timer = setTimeout(fetchAndSetStatus, 3000);
        return () => clearInterval(timer);
      }
      case 'pending': {
        if (isWideScreen && location.pathname !== routes.IDENT) {
          navigate(routes.IDENT);
        }

        const timer = setTimeout(fetchAndSetStatus, 3000);
        return () => clearInterval(timer);
      }
      case 'complete': {
        if (isWideScreen && location.pathname !== routes.IDENT) {
          navigate(routes.IDENT);
        }

        if (caseStatus.toSubmit.length && caseStatus.canRetry) {
          const timer = setTimeout(fetchAndSetStatus, 3000);
          return () => clearInterval(timer);
        } else {
          postEvent({
            uuid,
            event: 'WEB.JUMP.RESULT',
            details: {
              responseURI
            }
          });
        }

        if (caseStatus.reason !== 'hold') {
          const timer = setTimeout(() => {
            clearLocalStorage();

            // check if customer set specific redirect on case creation
            getBrowserRedirectURI({ uuid })
              .then(browserRedirect => {
                postEvent({
                  uuid,
                  event: 'WEB.JUMP.REDIRECT.BROWSER',
                  details: {
                    browserRedirect,
                    responseURI
                  }
                });

                // customer set specific redirect uri case-wise
                if (browserRedirect) {
                  window.location.href = prepareRedirectURL({
                    url: browserRedirect,
                    uuid
                  });
                }
              })
              .catch(error => {
                postEvent({
                  uuid,
                  event: 'WEB.JUMP.REDIRECT.ERROR',
                  details: {
                    error,
                    responseURI
                  }
                });
                // customer uses redirect uri frontend-wise
                if (responseURI) {
                  window.location.href = responseURI;
                }
              });
          }, 4000);

          return () => clearTimeout(timer);
        }
        break;
      }
      default:
        return;
    }
  }, [
    caseStatus,
    coBranding,
    dispatch,
    fetchAndSetStatus,
    isWideScreen,
    location.pathname,
    navigate,
    responseURI,
    uuid
  ]);

  return caseStatus;
};
