import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer } from '../components/FooterNew/Footer';
import { MiniQR } from '../components/MiniQRNew/MiniQR';
import { StateBox } from '../components/StateBoxNew/StateBox';
import { useLocationPrepare } from '../hooks/useLocationPrepare';
import { useStateValue } from '../hooks/useState';
import { useWideScreen } from '../hooks/useWide';
import { clearLocalStorage } from '../helper/clearLocalStorage';
import styled from 'styled-components';
import { LanguageSelector } from '../components/languageSelector/languageSelector';
import { LanguageSelectorWrapper } from '../components/languageSelector/languageSelector.styled';
import { supportedLanguages } from '../helper/supportedLangs';

const Button = styled.button`
  padding: 12px 16px;
  font-family: Heebo, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  background-color: #0e1528;
  border-radius: 4px;
  height: 40px;
  color: ${(props: any) => props.theme.white};
  border: none;
  cursor: pointer;

  :hover {
    background-color: #1b2a50;
  }

  :disabled {
    background-color: #dddee0;
    cursor: auto;
  }
`;

export const IdentScreen = () => {
  const caseStatus = useLocationPrepare();
  const [spLogo, setSpLogo] = useState('');
  const [title, setTitle] = useState('Identification is in progress...');
  const [subtitle, setSubtitle] = useState(
    'Finish the steps on the Nect Wallet to complete it. This page will close automatically when you are done.'
  );
  const [showCta, setShowCta] = useState(false);
  const [{ responseURI, browserRedirect, coBranding }] = useStateValue();

  const { t } = useTranslation();

  const [ctaText, setCtaText] = useState<string>(t('Back'));

  const isWide = useWideScreen();

  const redirectURL = responseURI ? responseURI : browserRedirect;

  useEffect(() => {
    if (coBranding?.logo_b64) {
      setSpLogo(coBranding.logo_b64);
    }
  }, [coBranding]);

  useEffect(() => {
    if (caseStatus) {
      if (caseStatus.reason === 'hold') {
        setTitle('On Hold');
        setSubtitle(
          'Please come back again later. Our expert is current unavailable. This may take until the next business day.'
        );
      } else if (
        caseStatus.reason === 'cancel' ||
        caseStatus.reason === 'timeout'
      ) {
        setTitle('Identification is complete');
        setSubtitle(
          'Please go back to our partner company and start a new identification'
        );
      } else if (
        caseStatus.state === 'pending' ||
        (caseStatus.state === 'created' && caseStatus.toSubmit.length) ||
        (caseStatus.state === 'complete' &&
          caseStatus.toSubmit.length &&
          caseStatus.canRetry)
      ) {
        setTitle('Identification is in progress...');
        setSubtitle(
          'Finish the steps on the Nect Wallet to complete it. This page will close automatically when you are done.'
        );
      }

      if (caseStatus.state === 'complete' && !caseStatus.canRetry) {
        setTitle('Your identification is successful');
        setSubtitle(
          redirectURL
            ? 'You will now be redirected to our partner. If this page doesn’t automatically take you back to the service page, you can click here:'
            : 'Follow the instructions on your Nect app.'
        );

        if (redirectURL && coBranding && coBranding.name) {
          setShowCta(true);
          if (coBranding.jumpBackButtonFreeText) {
            setCtaText(coBranding.jumpBackButtonFreeText);
          } else if (coBranding.jumpBackButton) {
            setCtaText(coBranding.jumpBackButton);
          } else if (coBranding.jump_name) {
            setCtaText(`${t('Back to the')} ${coBranding.jump_name}`);
          } else {
            setCtaText(`${t('Back to the')} ${coBranding.name}`);
          }
        }
      }
    }
  }, [caseStatus]);

  if (!isWide) {
    return null;
  }

  return (
    <div className="newPageRoot">
      {caseStatus?.state !== 'complete' && caseStatus?.reason === '' && (
        <div className="newPageMessageBanner">
          {t("Please don't close this page until the process is complete.")}
        </div>
      )}
      <LanguageSelectorWrapper>
        <LanguageSelector langs={supportedLanguages} />
      </LanguageSelectorWrapper>
      <div className="newPageWrapper">
        <div className="newPageHeaderLogos">
          <img
            style={{ height: 50 }}
            src={require('../assets/nect-logo-black.svg')}
            alt="Nect"
          />
          {isWide && spLogo && (
            <>
              <img
                src={require('../assets/close-cross.png')}
                style={{ height: 40, marginLeft: 16, marginRight: 16 }}
                alt="Close"
              />
              <img src={spLogo} style={{ height: 50 }} alt="Service Provider" />
            </>
          )}
        </div>

        <h1 className="newPageHeaderTitle">{t(title)}</h1>

        <p className="newPageHeaderSubtitle">{t(subtitle)}</p>

        <StateBox caseStatus={caseStatus} />

        {showCta && (
          <div style={{ padding: '24px' }}>
            <Button
              onClick={() => {
                clearLocalStorage();

                if (caseStatus.state === 'complete' && redirectURL) {
                  window.location = redirectURL;
                }
              }}
            >
              {ctaText}
            </Button>
          </div>
        )}
      </div>

      <div>
        <div style={{ padding: '48px 65px' }}>
          <MiniQR caseStatus={caseStatus} />
        </div>
        <Footer />
      </div>
    </div>
  );
};
