import { gracefulFetch } from './gracefulFetch';
import { postEvent } from './postEvent';

export const getESignStatus = async ({
  uuid,
  SignatoryID,
  SigningID,
  caseType
}: {
  uuid: string;
  SigningID?: string;
  SignatoryID?: string;
  caseType?: number;
}): Promise<boolean> => {
  let response: Response | null = null;

  if (caseType !== 1) {
    return true;
  }

  try {
    response = await gracefulFetch({
      fetch: async () =>
        fetch(
          `${process.env.REACT_APP_ESIGN_API}/v2/signings/${SigningID}/signatories/${SignatoryID}/status`,
          {
            cache: 'no-store',
            method: 'GET',
            headers: { 'Cache-Control': 'no-cache', pragma: 'no-cache' }
          }
        ),
      tries: 1
    });
  } catch (error) {
    postEvent({
      uuid,
      event: 'WEB.JUMP.ERROR',
      details: {
        info: 'getESignStatus',
        error: error?.message || error,
        stack: error?.stack
      }
    });
  }

  if (response && response.ok) {
    const json = await response.json();

    if (json.terminal === false) {
      return false;
    }
  } else {
    postEvent({
      uuid,
      event: 'WEB.JUMP.ERROR',
      details: {
        info: 'getESignStatus',
        status: response && response.status
      }
    });
  }

  return true;
};
