import React, { createContext, useContext, useReducer } from 'react';
import { prepareRedirectURL } from '../helper/prepareRedirectURL';

const actionTypes = {
  CHANGE_UUID: 'CHANGE_UUID',
  CHANGE_CASE_INFO: 'CHANGE_CASE_INFO',
  CHANGE_RESPONSE_URI: 'CHANGE_RESPONSE_URI',
  CHANGE_COBRANDING: 'CHANGE_COBRANDING',
  CHANGE_CASE_STATUS: 'CHANGE_CASE_STATUS',
  CHANGE_UUID_AND_RESPONSE_URI: 'CHANGE_UUID_AND_RESPONSE_URI',
  CHANGE_MAINTENANCE_STATUS: 'CHANGE_MAINTENANCE_STATUS',
  CHANGE_SHOW_START_BUTTON: 'CHANGE_SHOW_START_BUTTON',
  CHANGE_FEEDBACK_VISIBILITY: 'CHANGE_FEEDBACK_VISIBILITY'
};

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);
export const useStateValue = () => useContext(StateContext);

export const mainReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_UUID:
      return {
        ...state,
        uuid: action.payload
      };
    case actionTypes.CHANGE_SHOW_START_BUTTON:
      return {
        ...state,
        showStartButton: action.payload
      };
    case actionTypes.CHANGE_CASE_STATUS:
      return {
        ...state,
        caseStatus: action.payload
      };
    case actionTypes.CHANGE_COBRANDING:
      return {
        ...state,
        coBranding: action.payload
      };
    case actionTypes.CHANGE_CASE_INFO:
      return {
        ...state,
        ...action.payload
      };
    case actionTypes.CHANGE_MAINTENANCE_STATUS:
      return {
        ...state,
        ...action.payload
      };
    case actionTypes.CHANGE_RESPONSE_URI:
      return {
        ...state,
        responseURI: prepareRedirectURL({
          url: action.payload,
          uuid: state.uuid
        })
      };
    case actionTypes.CHANGE_UUID_AND_RESPONSE_URI:
      return {
        ...state,
        uuid: action.payload.uuid,
        responseURI: prepareRedirectURL({
          url: action.payload.responseURI,
          uuid: action.payload.uuid
        }),
        browserRedirect: prepareRedirectURL({
          url: action.payload.browserRedirect,
          uuid: action.payload.uuid
        })
      };
    case actionTypes.CHANGE_FEEDBACK_VISIBILITY:
      return {
        ...state,
        feedbackBoxVisible: action.payload
      };
    default:
      return state;
  }
};

export const changeUUIDAction = ({ uuid }) => {
  return {
    type: actionTypes.CHANGE_UUID,
    payload: uuid
  };
};

export const changeShowStartButton = ({ showStartButton }) => {
  return {
    type: actionTypes.CHANGE_SHOW_START_BUTTON,
    payload: showStartButton
  };
};

export const changeUUIDAndResponseURIAction = ({
  uuid,
  responseURI,
  browserRedirect
}) => {
  return {
    type: actionTypes.CHANGE_UUID_AND_RESPONSE_URI,
    payload: { uuid, responseURI, browserRedirect }
  };
};

export const changeCoBrandingAction = ({ coBranding }) => {
  return {
    type: actionTypes.CHANGE_COBRANDING,
    payload: coBranding
  };
};

export const changeCaseInfoAction = ({
  caseType,
  browserRedirect,
  hasChallengeResponse,
  eIDMode,
  esignData
}) => {
  return {
    type: actionTypes.CHANGE_CASE_INFO,
    payload: {
      caseType,
      browserRedirect,
      hasChallengeResponse,
      eIDMode,
      esignData
    }
  };
};

export const changeMaintenanceStatusAction = ({ maintenance }) => {
  return {
    type: actionTypes.CHANGE_MAINTENANCE_STATUS,
    payload: { maintenance }
  };
};

export const changeResponseURIAction = ({ responseURI }) => {
  return {
    type: actionTypes.CHANGE_RESPONSE_URI,
    payload: responseURI
  };
};

export const changeCaseStatusAction = ({ caseStatus }) => {
  return {
    type: actionTypes.CHANGE_CASE_STATUS,
    payload: caseStatus
  };
};

// export const changeDeepStartLinkAction = ({ deepStartLink }) => {
//   return {
//     type: actionTypes.CHANGE_CASE_STATUS,
//     payload: caseStatus
//   };
// };
